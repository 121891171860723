import React, { FC } from 'react'
import cx from 'classnames'
import { Button } from '@mui/material'

interface Props {
  isSelected: boolean
  onClick: () => void
  title: string
}

const ReservationTypesListItem: FC<Props> = ({ isSelected, onClick, title }) => (
  <Button
    color={'primary'}
    variant={isSelected ? 'contained' : 'outlined'}
    onClick={onClick}
    className={cx({ active: isSelected })}
    sx={{ width: 1, height: 1 }}
  >
    {title}
  </Button>
)

export default ReservationTypesListItem
