const appName = 'Bookinator'

function nullFunction() {
  return null
}

const mailPattern = /^\w+([.-]?\w+)*(\+\w+([.-]?\w+)*)?@\w+([.-]?\w+)*(.\w{2,3})+$/

const parseJson = <T>(jsonString: string): T => {
  try {
    return JSON.parse(jsonString) as T
  } catch (error) {
    throw Error(`Error parsing JSON data - ${JSON.stringify(error)}`)
  }
}

export const unwrap = <T>(variable: T | undefined | null): T => {
  if (!variable) {
    throw new Error('Variable must not be undefined or null')
  }

  return variable
}

const apiMessageToi18nKey = (text: string): string => {
  switch (text) {
    case 'Email and password do not match':
      return 'pages.login.wrongEmailOrPassword'
    case 'Registration was not successful. Maybe you are already registered?':
      return 'pages.register.registrationError'
  }

  return text
}

const pad = (x: number, width: number, z: string) => {
  z = z || '0'
  const n = x + ''
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

export { nullFunction, mailPattern, appName, parseJson, apiMessageToi18nKey, pad }
