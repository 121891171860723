import { ReservationFormData } from '../ReservationFormStateProvider'
import { CreateGroupReservationDto } from '../../../data/dtos'

export const getCreateGroupReservationDto = (
  form: ReservationFormData
): CreateGroupReservationDto => {
  if (!form.groupReservationSession) {
    throw new Error('Group reservation session is required')
  }

  if (
    !form.customer?.email ||
    !form.customer?.firstName ||
    !form.customer?.lastName ||
    !form.customer?.phoneNumber
  ) {
    throw new Error('Customer is required')
  }

  return {
    groupReservationSessionId: form.groupReservationSession.id,
    customer: {
      email: form.customer.email,
      firstName: form.customer.firstName,
      lastName: form.customer.lastName,
      phoneNumber: form.customer.phoneNumber,
    },
    notes: '',
  }
}
