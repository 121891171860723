import { useQuery } from '@tanstack/react-query'
import {
  getBranch,
  getEmployees,
  getGroupReservationSessions,
  getReservationAvailableTimes,
  getReservationTypes,
} from './apiFunctions'
import { DateTime } from 'luxon'

export const useBranch = (branchId: string) =>
  useQuery({
    queryKey: ['branch', branchId],
    queryFn: () => getBranch(branchId),
  })

export const useReservationTypes = (branchId: string | undefined) =>
  useQuery({
    queryKey: ['reservationTypes', branchId],
    queryFn: () => getReservationTypes(branchId!),
    enabled: !!branchId,
  })

export const useEmployees = (branchId: string | undefined) =>
  useQuery({
    queryKey: ['employees', branchId],
    queryFn: () => getEmployees(branchId!),
    enabled: !!branchId,
  })

export const useReservationAvailableTimes = (
  reservationTypeId: number,
  date: DateTime | undefined,
  employeeId: number,
  enabled = true
) =>
  useQuery({
    queryKey: ['reservationAvailableTimes', reservationTypeId, date, employeeId],
    queryFn: () => getReservationAvailableTimes(reservationTypeId, date!, employeeId),
    enabled: enabled && !!date,
  })

export const useGroupReservationSessions = (
  branchId: string | undefined,
  from: DateTime,
  until: DateTime,
  reservationTypeId: number,
  employeeId: number
) =>
  useQuery({
    queryKey: ['groupReservationSessions', branchId, from, until, reservationTypeId, employeeId],
    queryFn: () =>
      getGroupReservationSessions(branchId!, from, until, reservationTypeId, employeeId),
    enabled: Boolean(branchId),
  })
