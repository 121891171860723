import React, { FC } from 'react'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import BookinatorLogo from '../assets/svg/BookinatorLogo'
import { useTranslation } from 'react-i18next'
import packageJson from '../../package.json'

const Signature: FC = () => {
  const { palette } = useTheme()
  const { t } = useTranslation()

  return (
    <Stack direction={'column'} sx={{ color: palette.grey.A700 }}>
      <Typography variant={'caption'}>{t('common.youCanMakeThisReservationThanksTo')}</Typography>
      <Box m={1}>
        <BookinatorLogo />
      </Box>

      <Typography variant={'caption'} fontSize={'0.8em'} sx={{ opacity: 0.2 }}>
        v{packageJson.version}
      </Typography>
    </Stack>
  )
}

export default Signature
