import {
  BranchDto,
  CompanyDto,
  EmployeeDto,
  GroupReservationSessionDto,
  ReservationTypeDto,
} from './dtos'
import { Branch, Company, Employee, GroupReservationSession, ReservationType } from './models'
import { DateTime } from 'luxon'
import { parseApiDateTimeStringToLocal } from '../helpers/dateTimeService'

export const dtoToModel = {
  reservationType: (dto: ReservationTypeDto): ReservationType => dto,
  employee: (dto: EmployeeDto): Employee => ({
    ...dto,
    branch: dto.branch ? dtoToModel.branch(dto.branch) : undefined,
  }),
  branch: (dto: BranchDto): Branch => ({
    ...dto,
    company: dto.company ? dtoToModel.company(dto.company) : undefined,
  }),
  company: (dto: CompanyDto): Company => dto,
  groupReservationSession: (dto: GroupReservationSessionDto): GroupReservationSession => ({
    ...dto,
    startingAt: parseApiDateTimeStringToLocal(dto.startingAt),
    endingAt: parseApiDateTimeStringToLocal(dto.endingAt),
    employee: dtoToModel.employee(dto.employee),
    reservationType: dtoToModel.reservationType(dto.reservationType),
  }),
}
