import React, { useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import BaseStep from './BaseStep'
import {
  ReservationFormData,
  ReservationFormStateContext,
  setReservationFormDataAction,
  setReservationFormStepAction,
} from '../ReservationFormStateProvider'
import './Step1.scss'
import { Alert, Grid, Typography } from '@mui/material'
import { SubmitHandler } from 'react-hook-form/dist/types/form'
import useIsFirstRender from '../../../hooks/useIsFirstRender'
import ReservationTypesListItem from '../ReservationTypesListItem'
import { useReservationTypes } from '../../../data/queryFunctions'

const Step1: React.FC = () => {
  const { t } = useTranslation()

  const {
    formState: { data, branchId },
    dispatch,
  } = useContext(ReservationFormStateContext)

  const { data: reservationTypes, isLoading, error } = useReservationTypes(branchId)

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ReservationFormData>({ defaultValues: data })

  const isFirstRender = useIsFirstRender()

  useEffect(() => {
    if (isFirstRender) {
      dispatch(
        setReservationFormDataAction({
          ...data,
          employee: undefined,
          groupReservationSession: undefined,
        })
      )
    }
  }, [data, dispatch, isFirstRender])

  const onSubmit: SubmitHandler<ReservationFormData> = (formData) => {
    dispatch(setReservationFormDataAction({ ...data, reservationType: formData.reservationType }))
    dispatch(setReservationFormStepAction(2))
  }

  const individualReservationTypes = (reservationTypes ?? []).filter((_) => !_.isForGroups)
  const groupReservationTypes = (reservationTypes ?? []).filter((_) => _.isForGroups)

  const hasIndividualReservationTypes = individualReservationTypes.length > 0
  const hasGroupReservationTypes = groupReservationTypes.length > 0

  const showIndividualAndGroupTitles = hasIndividualReservationTypes && hasGroupReservationTypes

  // @ts-ignore
  const errorMessage = errors.reservationType?.message

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BaseStep
        title={t('components.reservationForm.selectService')}
        isLoading={isLoading}
        error={errorMessage}
      >
        <Controller
          defaultValue={data.reservationType}
          render={({ field: { onChange, value } }) => (
            <Grid container spacing={3}>
              {!isLoading && error && (
                <Grid xs={12} item>
                  <Alert severity={'error'}>
                    {t('common.somethingWentWrongPleaseTryAgainInAWhile')}
                  </Alert>
                </Grid>
              )}

              {!isLoading && (reservationTypes?.length ?? 0) === 0 && (
                <Grid xs={12} item>
                  <Alert severity={'info'}>
                    {t('components.reservationForm.thisCompanyDoesNotProvideAnyServicesYet')}
                  </Alert>
                </Grid>
              )}

              {showIndividualAndGroupTitles && (
                <Grid xs={12} item>
                  <Typography variant={'h6'}>{t('Services for individuals')}</Typography>
                </Grid>
              )}

              {!isLoading &&
                individualReservationTypes.map((type) => (
                  <Grid key={type.id} xs={12} sm={6} md={4} item>
                    <ReservationTypesListItem
                      isSelected={value?.id === type.id}
                      onClick={() => {
                        onChange(type)
                        onSubmit({ ...data, reservationType: type })
                      }}
                      title={type.name}
                    />
                  </Grid>
                ))}

              {showIndividualAndGroupTitles && (
                <Grid xs={12} item>
                  <Typography variant={'h6'}>{t('Services for groups')}</Typography>
                </Grid>
              )}

              {!isLoading &&
                groupReservationTypes.map((type) => (
                  <Grid key={type.id} xs={12} sm={6} md={4} item>
                    <ReservationTypesListItem
                      isSelected={value?.id === type.id}
                      onClick={() => {
                        onChange(type)
                        onSubmit({ ...data, reservationType: type })
                      }}
                      title={type.name}
                    />
                  </Grid>
                ))}
            </Grid>
          )}
          name='reservationType'
          rules={{
            required: t('errors.pleaseSelectOneOption'),
          }}
          control={control}
        />
      </BaseStep>
    </form>
  )
}

export default Step1
