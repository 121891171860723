import React, { FC, useContext, useEffect, useRef } from 'react'
import { ReservationFormStateContext } from './ReservationFormStateProvider'
import Step1 from './form-steps/Step1'
import Step2 from './form-steps/Step2'
import Step3 from './form-steps/Step3'
import Step4 from './form-steps/Step4'
import ReservationFormSummary from './ReservationFormSummary'
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import SummaryStep from './form-steps/SummaryStep'
import Header from '../../common/Header'
import { useBranch } from '../../data/queryFunctions'

interface Props {
  branchId: string
  isWithSummary?: boolean
}

const ReservationForm: FC<Props> = (props) => {
  const theme = useTheme()
  const {
    formState: { step },
  } = useContext(ReservationFormStateContext)

  const isSmallDisplay = useMediaQuery(theme.breakpoints.up('md'))

  const { data: branch, isLoading, error } = useBranch(props.branchId)

  const wrapperRef = useRef<HTMLDivElement>(null)
  const scrollToWrapperTop = () => wrapperRef.current?.scrollIntoView({ behavior: 'smooth' })

  useEffect(() => {
    scrollToWrapperTop()
  }, [step])

  return (
    <>
      <Grid item xs={12} md={props.isWithSummary ? 8 : 12} ref={wrapperRef}>
        {branch && !error && <Header branch={branch} isLoading={isLoading} />}

        {step === 1 && <Step1 />}
        {step === 2 && <Step2 />}
        {step === 3 && <Step3 />}
        {step === 4 && <Step4 />}
        {step === 5 && <SummaryStep />}
      </Grid>

      <Grid item xs={12} md={4}>
        <ReservationFormSummary isWithStepper={isSmallDisplay} />
      </Grid>
    </>
  )
}

ReservationForm.defaultProps = {
  isWithSummary: true,
}

export default ReservationForm
