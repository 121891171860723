import { DateTime } from 'luxon'

// export const appTimeZone = "Asia/Dubai";
export const appTimeZone = 'Europe/Budapest'
// export const appTimeZone = "UTC";

export const defaultDateFormat = 'yyyy-MM-dd'
export const defaultTimeFormat = 'HH:mm:ss'

export const getBrowserTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone

export const parseToUtc = (dateString: string): DateTime =>
  DateTime.fromISO(dateString, { zone: 'UTC' })

export const parseDateToLocal = (dateString: string): DateTime =>
  DateTime.fromISO(dateString, { zone: appTimeZone })

export const parseTimeToLocal = (input: string, format: string = defaultTimeFormat): DateTime =>
  DateTime.fromFormat(input, format, { zone: appTimeZone })

export const parseApiDateTimeStringToLocal = (dateString: string): DateTime =>
  DateTime.fromISO(dateString, { zone: 'UTC' }).setZone(appTimeZone)

export const toApiDateTimeString = (date: DateTime): string =>
  date.set({ millisecond: 0 }).toUTC().toISO({
    includeOffset: false,
    suppressMilliseconds: true,
  })

export const toApiDateString = (date: DateTime): string => date.toFormat(defaultDateFormat)

export const toApiTimeString = (date: DateTime): string => date.toFormat(defaultTimeFormat)

export const utcToLocal = (date: DateTime): DateTime => date.setZone(appTimeZone)

export const localToUTC = (date: DateTime): DateTime => date.setZone('UTC')

export const nowLocal = () => DateTime.local({ zone: appTimeZone })

export const toJSDate = (dateTime: DateTime): Date => {
  const browserDateTime = DateTime.utc().setZone(getBrowserTimeZone()).set({
    year: dateTime.year,
    month: dateTime.month,
    day: dateTime.day,
    hour: dateTime.hour,
    minute: dateTime.minute,
    second: dateTime.second,
  })

  return browserDateTime.toJSDate()
}

/**
 *
 * @param start Start date (included in interval)
 * @param end End date (not included in interval)
 */
export const eachDayOfInterval = (start: DateTime, end: DateTime): DateTime[] => {
  let interval: DateTime[] = []
  let currentDate = start

  while (currentDate < end) {
    interval.push(currentDate)
    currentDate = currentDate.plus({ days: 1 })
  }

  return interval
}

/**
 * Set date to @originalTime without changing displayed time and keep time zone from @originalTime.
 * Useful for inputs changing just date.
 * @param originalTime Original time
 * @param newDate      New date in local time zone
 *
 * @return New datetime in UTC
 */
export const setDateWithoutChangingTime = (originalTime: DateTime, newDate: DateTime) =>
  DateTime.fromObject(
    {
      year: newDate.year,
      month: newDate.month,
      day: newDate.day,
      hour: originalTime.hour,
      minute: originalTime.minute,
      second: originalTime.second,
    },
    { zone: originalTime.zone }
  )

export const isSameDay = (first: DateTime, second: DateTime): boolean => {
  const firstComparable = first.setZone('UTC')
  const secondComparable = second.setZone('UTC')

  return (
    firstComparable.year === secondComparable.year &&
    firstComparable.month === secondComparable.month &&
    firstComparable.day === secondComparable.day
  )
}

export const getStartDayOfMonth = (year: number, month: number): DateTime =>
  DateTime.local().set({
    year: year,
    month: month,
    day: 1,
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  })

export const getEndDayOfMonth = (year: number, month: number): DateTime => {
  const startOfNextMonth = getStartDayOfMonth(year, month).plus({ months: 1 })

  return startOfNextMonth.minus({ days: 1 })
}
