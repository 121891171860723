import {
  CreateGroupReservationDto,
  EmployeeDto,
  GroupReservationSessionDto,
  ReservationTypeDto,
} from './dtos'
import { apiUrls } from '../helpers/urls'
import { dtoToModel } from './dtoToModel'
import { DateTime } from 'luxon'
import { axiosInstance } from '../config/axios'
import { toApiDateTimeString } from '../helpers/dateTimeService'

export const getBranch = (branchId: string) =>
  axiosInstance.get(apiUrls.branch(branchId)).then((_) => dtoToModel.branch(_.data))

export const getReservationTypes = (branchId: string) =>
  axiosInstance
    .get<ReservationTypeDto[]>(apiUrls.reservationTypesOfBranch(branchId))
    .then((_) => _.data.map(dtoToModel.reservationType))

export const getEmployees = (branchId: string) =>
  axiosInstance
    .get<EmployeeDto[]>(apiUrls.employeesOfBranch(branchId))
    .then((_) => _.data.map(dtoToModel.employee))

export const getReservationAvailableTimes = (
  reservationTypeId: number,
  date: DateTime,
  employeeId?: number
) =>
  axiosInstance
    .get<string[]>(apiUrls.availableReservationTimes(reservationTypeId, date, employeeId))
    .then((_) => _.data)

export const getGroupReservationSessions = (
  branchId: string,
  from: DateTime,
  until: DateTime,
  reservationTypeId: number,
  employeeId: number
) =>
  axiosInstance
    .get<GroupReservationSessionDto[]>(apiUrls.groupReservationSessions, {
      params: {
        branchId: branchId,
        from: toApiDateTimeString(from),
        until: toApiDateTimeString(until),
        reservationTypeId: reservationTypeId,
        employeeId: employeeId,
      },
    })
    .then((_) => _.data.map(dtoToModel.groupReservationSession))

export const createGroupReservation = (groupReservation: CreateGroupReservationDto) =>
  axiosInstance
    .post<number>(apiUrls.groupReservations, groupReservation)
    .then((response) => response.data)
