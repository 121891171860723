import React, { useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import './Step1.scss'
import { useTranslation } from 'react-i18next'
import BaseStep from './BaseStep'
import {
  ReservationFormData,
  ReservationFormStateContext,
  setReservationFormDataAction,
  setReservationFormStepAction,
} from '../ReservationFormStateProvider'
import { Button, Grid } from '@mui/material'
import { SubmitHandler } from 'react-hook-form/dist/types/form'
import useIsFirstRender from '../../../hooks/useIsFirstRender'
import { useEmployees } from '../../../data/queryFunctions'
import { DateTime } from 'luxon'

const Step2: React.FC = () => {
  const {
    formState: { data, branchId },
    dispatch,
  } = useContext(ReservationFormStateContext)

  const { t } = useTranslation()

  const { data: employees, isLoading } = useEmployees(branchId)

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ReservationFormData>({ defaultValues: data })

  const isFirstRender = useIsFirstRender()

  useEffect(() => {
    if (isFirstRender) {
      dispatch(
        setReservationFormDataAction({
          ...data,
          groupReservationSession: undefined,
        })
      )
    }
  }, [data, dispatch, isFirstRender])

  const onSubmit: SubmitHandler<ReservationFormData> = (formData) => {
    dispatch(setReservationFormDataAction({ ...data, employee: formData.employee }))
    dispatch(setReservationFormStepAction(3))
  }

  // @ts-ignore
  const errorMessage = errors.employee?.message

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BaseStep
        title={t('components.reservationForm.selectWorker')}
        isLoading={isLoading}
        error={errorMessage}
      >
        <Controller
          defaultValue={data.employee}
          render={({ field: { onChange, value } }) => (
            <Grid container direction={'row'} spacing={1}>
              {employees?.map((employee) => (
                <Grid item xs={12} sm={6} md={4} key={employee.id}>
                  <Button
                    onClick={() => {
                      onChange(employee)
                      onSubmit({ ...data, employee: employee })
                    }}
                    variant={employee.id === value?.id ? 'contained' : 'outlined'}
                    sx={{ width: 1, height: 1 }}
                  >
                    {employee.firstName} {employee.lastName}
                  </Button>
                </Grid>
              ))}
            </Grid>
          )}
          name='employee'
          rules={{
            required: t('errors.pleaseSelectOneOption') as string,
          }}
          control={control}
        />
      </BaseStep>
    </form>
  )
}

export default Step2
