import React, { FC, ReactElement, useContext } from 'react'
import { Grid, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReservationFormStateContext } from '../ReservationFormStateProvider'
import BaseStep from './BaseStep'
import CheckIcon from '@mui/icons-material/Check'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'

interface Props {}

const SummaryHeading: FC<{ title: string }> = ({ title }) => (
  <Typography variant={'h6'} sx={{ mb: 1 }}>
    {title}
  </Typography>
)

const iconProps = { sx: { mr: 2 }, color: 'primary' as 'primary' }

const SummaryItem: FC<{
  title: string
  items: { icon?: ReactElement; text: string | undefined }[]
}> = ({ title, items }) => (
  <Grid md={6} item>
    <Stack direction={'column'}>
      <SummaryHeading title={title} />

      {items.map((_, index) => (
        <Stack key={index} direction={'row'} alignItems={'center'} sx={{ mb: 1, pl: 1 }}>
          {_.icon ?? <CheckIcon {...iconProps} />}
          <Typography>{_.text}</Typography>
        </Stack>
      ))}
    </Stack>
  </Grid>
)

const SummaryStep: FC<Props> = (props) => {
  const { t } = useTranslation()
  const {
    formState: { data },
  } = useContext(ReservationFormStateContext)

  const reservationDate = data.groupReservationSession?.startingAt ?? data.date

  return (
    <BaseStep title={t('summary.title')}>
      <Grid container spacing={5} sx={{ px: 2, mb: 5 }}>
        <SummaryItem title={t('summary.service')} items={[{ text: data.reservationType?.name }]} />
        <SummaryItem
          title={t('summary.employee')}
          items={[
            {
              text: `${data.employee?.firstName} ${data.employee?.lastName}`,
              icon: <PersonOutlineOutlinedIcon {...iconProps} />,
            },
          ]}
        />
        <SummaryItem
          title={t('summary.dateAndTime')}
          items={[
            {
              text: reservationDate?.toFormat('dd. MM. yyyy (cccc)') ?? '',
              icon: <TodayOutlinedIcon {...iconProps} />,
            },
            {
              text: reservationDate?.toFormat('HH:mm') ?? '',
              icon: <AccessTimeOutlinedIcon {...iconProps} />,
            },
          ]}
        />
        <SummaryItem
          title={t('summary.contact')}
          items={[
            {
              text: `${data.customer?.lastName}, ${data.customer?.firstName}`,
              icon: <PersonOutlineOutlinedIcon {...iconProps} />,
            },
            {
              text: data.customer?.phoneNumber,
              icon: <LocalPhoneOutlinedIcon {...iconProps} />,
            },
            {
              text: data.customer?.email,
              icon: <EmailOutlinedIcon {...iconProps} />,
            },
          ]}
        />
      </Grid>
    </BaseStep>
  )
}

export default SummaryStep
