import React from 'react'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useGroupReservationSessions } from '../../../data/queryFunctions'
import { useReservationFormState } from '../ReservationFormStateProvider'
import { unwrap } from '../../../helpers/utils'
import { GroupReservationSession } from '../../../data/models'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'

interface Props {
  onChange: (reservation: GroupReservationSession) => void
}

const Step3SelectGroupReservationSession = ({ onChange }: Props) => {
  const { t } = useTranslation()
  const { palette } = useTheme()

  const {
    formState: { data, branchId },
    dispatch,
  } = useReservationFormState()

  const isGroupReservation = data.reservationType?.isForGroups
  const reservationTypeId = unwrap(data.reservationType?.id)
  const employeeId = unwrap(data.employee?.id)
  const date = data.date

  const {
    data: groupReservations,
    isLoading,
    isLoadingError,
  } = useGroupReservationSessions(
    branchId,
    date.startOf('day'),
    date.endOf('day'),
    reservationTypeId,
    employeeId
  )

  const onClick = (groupReservation: GroupReservationSession) => {
    onChange(groupReservation)
  }

  return (
    <Grid container spacing={1} mt={1}>
      <Grid item xs={12}>
        <Typography variant={'overline'} color={palette.grey['600']}>
          {t('components.reservationForm.time')}
        </Typography>

        {!isLoading && (groupReservations?.length ?? 0) === 0 && (
          <Alert severity={'info'}>
            {t('components.reservationForm.weAreSorryButThereIsNoAvailableTimeForThisDate')}
          </Alert>
        )}

        {isLoadingError && (
          <Alert severity={'error'}>{t('common.somethingWentWrongPleaseTryAgainInAWhile')}</Alert>
        )}
      </Grid>

      {isLoading && (
        <Box sx={{ width: 1, display: 'flex', my: 3, justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}

      {groupReservations?.map((groupReservation, index) => (
        <Grid item xs={12} md={6} key={groupReservation.id}>
          <Button onClick={() => onClick(groupReservation)} sx={{ width: 1 }}>
            <Stack direction={'column'} alignItems={'flex-start'} gap={0.4} sx={{ width: 1 }}>
              <Typography sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                {groupReservation.reservationType.name}
              </Typography>
              <Stack direction={'row'} alignItems={'center'} gap={1}>
                <AccessTimeOutlinedIcon fontSize={'small'} />
                <Typography variant={'caption'}>
                  {groupReservation.startingAt.toFormat('HH:mm')} -{' '}
                  {groupReservation.endingAt.toFormat('HH:mm')}
                </Typography>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} gap={1}>
                <PersonOutlineOutlinedIcon fontSize={'small'} />
                <Typography variant={'caption'}>
                  {groupReservation.currentOccupancy} / {groupReservation.maxCapacity}
                </Typography>
              </Stack>
            </Stack>
          </Button>
        </Grid>
      ))}
    </Grid>
  )
}

export default Step3SelectGroupReservationSession
