import React, { useContext, useRef } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import BaseStep from './BaseStep'
import {
  ReservationFormData,
  ReservationFormStateContext,
  setReservationFormDataAction,
  setReservationFormStepAction,
} from '../ReservationFormStateProvider'
import { DateTime } from 'luxon'
import { StaticDatePicker } from '@mui/lab'
import { Grid, TextField, useTheme } from '@mui/material'
import Step3SelectTime from './Step3SelectTime'
import { useReservationAvailableTimes } from '../../../data/queryFunctions'
import { unwrap } from '../../../helpers/utils'
import Step3SelectGroupReservationSession from './Step3SelectGroupReservationSession'
import { GroupReservationSession } from '../../../data/models'

const Step3: React.FC = () => {
  const {
    formState: { data },
    dispatch,
  } = useContext(ReservationFormStateContext)

  const { t } = useTranslation()
  const { spacing } = useTheme()

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
  } = useForm<ReservationFormData>({
    defaultValues: data,
  })

  const availableTimesWrapperRef = useRef<HTMLDivElement>(null)
  const scrollToAvailableTimes = () =>
    availableTimesWrapperRef.current?.scrollIntoView({ behavior: 'smooth' })

  const isGroupReservation = data.reservationType?.isForGroups
  const reservationTypeId = unwrap(data.reservationType?.id)
  const employeeId = unwrap(data.employee?.id)
  const date = data.date

  const {
    data: availableTimes,
    isLoading: isLoadingAvailableTimes,
    error: isAvailableTimesError,
  } = useReservationAvailableTimes(reservationTypeId, date, employeeId, !isGroupReservation)

  const onSubmit: SubmitHandler<ReservationFormData> = (formData) => {
    dispatch(
      setReservationFormDataAction({
        ...data,
        date: formData.date as DateTime,
        groupReservationSession: formData.groupReservationSession as
          | GroupReservationSession
          | undefined,
      })
    )
    dispatch(setReservationFormStepAction(4))
  }

  // @ts-ignore
  const errorMessage = errors.date?.message

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BaseStep title={t('components.reservationForm.selectDateAndTime')} error={errorMessage}>
        <Controller
          {...register('date')}
          render={({ field: { onChange, value } }) => (
            <Grid container spacing={1}>
              <Grid item xs={12} lg={6} sx={{ marginLeft: { xs: spacing(-4), sm: 0 } }}>
                <StaticDatePicker<DateTime>
                  label={t('components.reservationForm.date')}
                  inputFormat='dd. MM. yyyy'
                  value={value as DateTime}
                  onChange={(_) => {
                    onChange(_)
                    scrollToAvailableTimes()

                    if (_) {
                      dispatch(setReservationFormDataAction({ ...data, date: _ }))
                    }
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  orientation={'portrait'}
                  disablePast
                  ignoreInvalidInputs
                />
              </Grid>

              <Grid
                item
                xs={12}
                lg={6}
                justifyContent={'center'}
                alignItems={'center'}
                ref={availableTimesWrapperRef}
              >
                {value && !isGroupReservation && (
                  <Step3SelectTime
                    selectedDate={value as DateTime}
                    onChange={(_) => {
                      onChange(_)
                      onSubmit({ ...data, date: _ })
                    }}
                    availableTimes={availableTimes ?? []}
                    isLoading={isLoadingAvailableTimes}
                    isLoadingError={!!isAvailableTimesError}
                  />
                )}

                {value && isGroupReservation && (
                  <Step3SelectGroupReservationSession
                    onChange={(session) => onSubmit({ ...data, groupReservationSession: session })}
                  />
                )}
              </Grid>
            </Grid>
          )}
          name='date'
          rules={{
            required: t('errors.required'),
            validate: (_) => {
              const isValid =
                availableTimes?.find((__) => __ === _?.toFormat('HH:mm')) !== undefined

              return isValid ? undefined : t('errors.pleaseSelectTimeFromList')
            },
          }}
          control={control}
        />
      </BaseStep>
    </form>
  )
}

export default Step3
